import "core-js/stable";
import "regenerator-runtime/runtime";
import ScrollReveal from 'scrollreveal';

(function() {
    let ready = function() {
        
        ScrollReveal().reveal('.sr-slide-up', {
            distance: '100px',
            origin: 'bottom',
            opacity: 0,
            duration: 1600,
            easing: 'cubic-bezier(.39,.01,.59,1)',
            reset: false,
            viewOffset: {
                top: 100,
                bottom: 100
            }

        });

        ScrollReveal().reveal('.sr-slide-up-stagger', {
            interval: 400,
            reset: false,
            distance: '100px',
            easing: 'cubic-bezier(.39,.01,.59,1)',
            duration: 1600,
            opacity: 0,
            viewOffset: {
                top: 100,
                bottom: 100
            }
        });
    };
      
    if ( document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll)) {
        ready();
    } else {
        document.addEventListener("DOMContentLoaded", ready);
    }
})();